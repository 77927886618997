export default [
    {
        path: '/evaluationTask',
        component: () => import("@/views/home/evaluationTask/index.vue"),
        meta: {
            title: "测评任务"
        },
    },
    {

        path: '/evaluationDetails',
        component: () => import("@/views/home/evaluationDetails/index.vue"),
        meta: {
            title: "测评详情"
        },
    },
    {
        path: '/guageTest',
        component: () => import("@/views/home/guageTest/index.vue"),
        meta: {
            title: "量表测评"
        },
    }
]