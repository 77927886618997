import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
//vant
import "@/utils/useVant"
//rem适配
import "@/utils/flexible"
//初始化css
import "@/assets/styles/initcss.css"
//自定义指令loadding
import "@/utils/loadding.js"

//弹出消息
import registerPopup from "@/components/popup/js/index"
Vue.use(registerPopup)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
