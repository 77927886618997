import Vue from "vue";
// 注册一个全局自定义指令 `v-loadding`
import { Loading } from "vant";
Vue.directive('loadding', {
    // 当绑定元素插入到DOM中
    inserted: function (el, binding) {
        // 创建一个遮罩层
        const mask = document.createElement('div');
        mask.className = 'loadding-mask';
        mask.style.display = 'none';

        // 创建loadding图标
        const loaddingIcon = document.createElement('div');

        loaddingIcon.className = 'loadding-icon';
        loaddingIcon.innerHTML = '<span class="van-loading__spinner van-loading__spinner--circular" style="color: rgb(255,255,255);"><svg viewBox="25 25 50 50" class="van-loading__circular"><circle cx="50" cy="50" r="20" fill="none"></circle></svg></span>';

        // 将遮罩层和图标添加到元素上
        mask.appendChild(loaddingIcon);
        el.appendChild(mask);

        // 绑定显示和隐藏逻辑
        binding.value ? mask.style.display = 'block' : 'none';

 
    },
    update(el, binding) {
        const mask = document.querySelector('.loadding-mask');
        !binding.value ? mask&&el.removeChild(mask) : '';
    },
});