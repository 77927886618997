


export default [
    {
        path: "/teamEvaluateTask",
        component: () => import("@/views/home/teamEvaluateTask/index.vue"),
        meta: {
            title: "团体测评"
        }
    },
    {
        path: "/teamEvaluate",
        component: () => import("@/views/home/teamEvaluateTask/teamEvaluate/index.vue"),
        meta: {
            title: "团体测评做题"
        }
    },
]