
export default [
    {
        path: "/home",
        component: () => import("@/views/home/index.vue"),
        children: [
            {
                path: "/home",
                redirect: "/home/index",
            },
            {
                path: "index",
                component: () => import("@/views/home/index/index.vue"),
                meta: {
                    title: "首页"
                }
            }
        ]
    }
]