import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    INFO: {}
  },
  getters: {
  },
  mutations: {
    //存储登录的账号信息
    SET_INFO(state, params) {
      state.INFO = params;
    },
    // 清空登录的信心
    CLEAR_INFO(state) {
      state.INFO = "";
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({
    storage: window.localStorage, // 或者 localStorage
  })]
})
