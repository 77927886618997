import Vue from 'vue'
import VueRouter from 'vue-router'
import home from "@/router/home"
import evaluation from "@/router/evaluation"
import evaluationReport from './evaluationReport'
import teamEvaluate from './teamEvaluate'
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: '/login',
  },
  {
    path: "/login",
    component: () => import("@/views/login/index.vue")
  },
  ...home,
  ...evaluation,
  ...evaluationReport,
  ...teamEvaluate
]

const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,
  routes
})

export default router
