import Vue from "vue";
import popup from "@/components/popup/index.vue"

popup.install = (data) => {
    const Popup = Vue.extend(popup);
    const PopupDOM = new Popup({ data }).$mount();

    const body = document.body;
    body.appendChild(PopupDOM.$el);

    Vue.nextTick(() => {
        PopupDOM.isShow = true;
        // show 和弹窗组件里的show对应，用于控制显隐  
        if (!data.noHaveTime) {
            setTimeout(() => {
                body.removeChild(PopupDOM.$el);
            }, 2000)
        }
    })
}

const registerPopup = () => {

    Vue.prototype.$popup = popup.install;
}

export default registerPopup;