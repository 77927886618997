export default [
    {
        path: "/evaluationReport",
        component: () => import("@/views/home/evaluationReport/index.vue"),
        meta: {
            title: "测评报告"
        }
    },
    {
        path: "/evaluationReportDetail",
        component: () => import("@/views/home/evaluationReportDetail/index.vue"),
        meta: {
            title: "报告详情"
        }
    },
]